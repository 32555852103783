




































































































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { v4 as uuid } from "uuid";
import agent from "@/helpers/agent";
import {
  BusinessDocumentType,
  DocumentProfile,
  DocumentStatus,
  ProfileDocument,
} from "@/models/businessDocument";
import ManagePdf from "@/components/ManagePdf.vue";
import { AppEventBus, AppEventBusEvents } from "@/helpers/event_bus";
import { namespace } from "vuex-class";
import { BusinessType } from "@/models/businessApplication";

const app = namespace("app");

interface ProfileStatus {
  id: number;
  name: string;
  status: string;
}
@Component({
  components: {
    ManagePdf,
  },
})
export default class BusinessProfile extends Vue {
  @app.Getter authHeader!: any;

  step = 1;

  tradingLicense: File | null = null;
  certificateOfIncoporation: File | null = null;
  memorandumAndArticlesOfAssociation: File | null = null;
  directorsIdCopy: File | null = null;
  leaseAgreement: File | null = null;
  formJ: File | null = null;
  moveToNextStep = true;
  profileDocuments: ProfileDocument[] = [];

  loading = true;
  data: ProfileStatus[] = [];

  idCopies: ProfileDocument[] = [];
  filter = "";
  columns = [
    {
      name: "name",
      required: true,
      label: "Name",
      align: "left",
      field: (row: ProfileDocument) => row.fileName,
      sortable: true,
    },
    {
      name: "author",
      required: true,
      label: "Author",
      align: "left",
      field: (row: ProfileDocument) => row.authorName,
      sortable: true,
    },
  ];
  get profile() {
    return DocumentProfile.Legal;
  }
  get tradingLicenceType() {
    return BusinessDocumentType.TradingLicence;
  }
  get certificateOfIncorporationType() {
    return BusinessDocumentType.CertificateOfIncorporation;
  }
  get memorandumAndArticlesOfAssociationType() {
    return BusinessDocumentType.MemorandumAndArticlesOfAssociation;
  }
  get idCopyType() {
    return BusinessDocumentType.IDCopy;
  }
  get isCompany() {
    return BusinessType.Enterprise === Number(this.$route.query.type);
  }
  get leaseAgreementType() {
    return BusinessDocumentType.LeaseAgreement;
  }
  get formJType() {
    return BusinessDocumentType.FormJ;
  }
  @Watch("step")
  onSubCategoryChanged(val: number) {
    this.$router.replace({ query: { ...this.$route.query, step: `${val}` } });
  }
  documentExists(type: BusinessDocumentType) {
    const index = this.profileDocuments.findIndex((x) => x.type === type);
    return index > -1 ? true : false;
  }
  getDocumentId(type: BusinessDocumentType) {
    const document = this.profileDocuments.find((x) => x.type === type);
    return document ? document.id : null;
  }
  getDocumentAuthor(type: BusinessDocumentType) {
    const document = this.profileDocuments.find((x) => x.type === type);
    return document ? `By ${document.authorName}` : null;
  }
  getFields(type: BusinessDocumentType, profileOrder: number) {
    return [
      {
        name: "type",
        value: type,
      },
      {
        name: "documentProfile",
        value: DocumentProfile.Legal,
      },
      {
        name: "profileOrder",
        value: profileOrder,
      },
      {
        name: "applicationId",
        value: this.$route.query.id as string,
      },
      {
        name: "documentId",
        value: uuid(),
      },
    ];
  }
  getStatus(status: DocumentStatus) {
    return DocumentStatus[status];
  }
  routeToProfiles() {
    this.$router.push({
      name: "ProfilesApplication",
      query: { ...this.$route.query },
    });
  }
  getProfileDocuments() {
    if (this.$route.query.id) {
      agent.BusinessDocuments.getProfileDocuments(
        this.$route.query.id as string,
        DocumentProfile.Legal
      ).then((documents) => {
        this.loading = false;
        this.profileDocuments = documents;
        this.getIdCopies();
      });
      this.loading = false;
    }
  }
  getIdCopies() {
    this.loading = true;
    if (this.$route.query.id) {
      agent.BusinessDocuments.getProfileDocumentsByType(
        this.$route.query.id as string,
        BusinessDocumentType.IDCopy
      ).then((documents) => {
        this.loading = false;
        this.idCopies = documents;
      });
      this.loading = false;
    }
  }
  created() {
    if (this.$route.query.step) {
      this.step = parseInt(this.$route.query.step as string);
    }
  }
  mounted() {
    AppEventBus.$on(AppEventBusEvents.changeStatusEvent, () => {
      if (this.step > 3) this.routeToProfiles();
      this.step++;
    });
    AppEventBus.$on(AppEventBusEvents.deleteDocumentEvent, (id: string) => {
      this.profileDocuments = this.profileDocuments.filter((x) => x.id != id);
      this.step++;
    });
    this.getProfileDocuments();
  }
  beforeDestroy() {
    AppEventBus.$off(AppEventBusEvents.deleteDocumentEvent);
  }
}
