























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import PdfViewer from "@/components/PdfViewer.vue";
import agent from "@/helpers/agent";
import {
  BusinessDocument,
  BusinessDocumentType,
  DocumentStatus,
} from "@/models/businessDocument";
import Notifier from "@/helpers/notifier";
import { AppEventBus, AppEventBusEvents } from "@/helpers/event_bus";
import Comments from "@/components/Comments.vue";
import Reject from "@/components/Reject.vue";
import { CommentType } from "@/models/comment";
import { namespace } from "vuex-class";
import { AppUser } from "@/models/user";

const app = namespace("app");

@Component({ components: { PdfViewer, Comments, Reject } })
export default class ManagePdf extends Vue {
  @app.Getter user!: AppUser;

  @Prop({ required: true })
  id!: string;
  @Prop({ required: true })
  title!: string;
  @Prop({ required: true })
  subtitle!: string;
  @Prop({ required: false, default: false })
  goback!: boolean;

  commentType = CommentType.Document;
  document: BusinessDocument = {
    documentID: "",
    filePath: "",
    originalFileName: "",
    documentProfile: 0,
    type: BusinessDocumentType.CoverLetter,
    comment: "",
    profileOrder: 0,
    isValid: false,
    isPass: false,
    isReviewed: false,
    isAlternateDocRequest: false,
    isAlternateDocReturned: false,
    status: DocumentStatus.Pending,
    profile: 0,
    authorID: "",
    businessApplicationID: "",
    businessApplication: "",
  };

  showDialog = false;
  showRejectDialog = false;

  get path() {
    return `/api/documents/get-document/${this.id}?inline=true`;
  }
  get showDelete() {
    return this.document.authorID === this.user.id;
  }
  accept() {
    agent.BusinessDocuments.updateStatus(this.id, DocumentStatus.Accepted).then(
      () => {
        AppEventBus.$emit(AppEventBusEvents.changeStatusEvent);
        Notifier.showPositive("Document successfully accepted");
      }
    );
  }
  deleteDocument() {
    agent.BusinessDocuments.delete(this.id).then(() => {
      AppEventBus.$emit(AppEventBusEvents.deleteDocumentEvent, this.id);
      Notifier.showPositive("Document successfully deleted.");
      if (this.goback) this.$router.go(-1);
    });
  }
  reject() {
    agent.BusinessDocuments.updateStatus(this.id, DocumentStatus.Rejected).then(
      () => {
        AppEventBus.$emit(AppEventBusEvents.changeStatusEvent);
        Notifier.showPositive("Document has been rejected");
      }
    );
  }
 
}
