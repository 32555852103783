



import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import PDFObject from "pdfobject";
import { v4 as uuid } from "uuid";

@Component
export default class PdfViewer extends Vue {
  @Prop({ required: true })
  path!: string;

  id = `pdf-view-${uuid()}`;

  mounted() {
    PDFObject.embed(`${this.path}`, `#${this.id}`);
  }
}
