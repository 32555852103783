import { render, staticRenderFns } from "./Comments.vue?vue&type=template&id=24ba1b74&scoped=true&"
import script from "./Comments.vue?vue&type=script&lang=ts&"
export * from "./Comments.vue?vue&type=script&lang=ts&"
import style0 from "./Comments.vue?vue&type=style&index=0&id=24ba1b74&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ba1b74",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSpinnerOrbit from 'quasar/src/components/spinner/QSpinnerOrbit.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QToolbar,QAvatar,QToolbarTitle,QBtn,QCardSection,QTimeline,QTimelineEntry,QExpansionItem,QForm,QInput,QIcon,QScrollArea,QEditor,QCheckbox,QSpinnerOrbit});qInstall(component, 'directives', {ClosePopup});
