





























































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Comment, { CommentType } from "@/models/comment";
import { namespace } from "vuex-class";
import { AppUser } from "@/models/user";
import agent from "@/helpers/agent";
import Notifier from "@/helpers/notifier";
import { v4 as uuid } from "uuid";
import { format } from "date-fns";

const app = namespace("app");

@Component
export default class CommentsView extends Vue {
  @app.Getter user!: AppUser;

  @Prop({ required: true })
  id!: string;

  @Prop({ required: true })
  type!: CommentType;

  comments: Comment[] = [];
  loading = false;
  openComment = false;

  activeComment: Comment = {
    commentID: "",
    id: "",
    title: "",
    body: "",
    isPublic: false,
    date: new Date(),
    type: CommentType.Document,
    authorID: "",
  };
  body =
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

  addComment() {
    this.loading = true;
    this.activeComment.author = undefined;
    if (this.activeComment.commentID.length === 0) {
      const newComment: Comment = {
        ...this.activeComment,
        commentID: uuid(),
        id: this.id,
        type: this.type,
        authorID: this.user.id,
      };
      agent.Comments.create(newComment).then(() => {
        Notifier.showPositive("Comment successfully added.");
        this.loading = false;
        this.getComments();
        this.openComment = false;
      });
      return;
    }
    agent.Comments.update(this.activeComment).then(() => {
      Notifier.showPositive("Comment successfully updated.");
      this.loading = false;
      this.openComment = false;
    });
  }
  resetComment() {
    this.activeComment = {
      commentID: "",
      id: "",
      title: "",
      isPublic: false,
      body: "",
      date: new Date(),
      type: CommentType.Document,
      authorID: "",
    };
  }
  removeComment() {
    if (this.activeComment.commentID.length === 0) return;

    agent.Comments.delete(this.activeComment.commentID).then(() => {
      Notifier.showInfo("Comment successfully deleted.");
      this.comments = this.comments.filter(
        (x) => x.commentID != this.activeComment.commentID
      );
      this.openComment = false;
      this.resetComment();
    });
  }
  format(val: any) {
    return format(new Date(val), "d MMMM, yyyy");
  }
  getComments() {
    agent.Comments.list(this.id, "", true).then(
      (comments) => (this.comments = comments.rows)
    );
  }
  mounted() {
    this.getComments();
  }
}
