import { AppUser } from "./user";

export default interface Comment {
    commentID: string;
    id: string;
    date: Date;
    title: string;
    isPublic: boolean;
    body: string;
    type: CommentType;
    authorID: string;
    author?: AppUser;
}
export enum CommentType {
    Document,
    Application,
    Portfolio
}
export interface CommentVM {
    title: string;
    body: string;
}