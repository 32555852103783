










































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import agent from "@/helpers/agent";
import Notifier from "@/helpers/notifier";
import RejectVM, { RejectTo } from "@/models/reject";
import Loader from "@/helpers/loader";
import { NIL as NIL_UUID } from "uuid";

const app = namespace("app");

@Component
export default class CommentsView extends Vue {
  @Prop({ required: true })
  id!: string;

  rejectVM: RejectVM = {
    id: "",
    comment: "",
  };
  loading = false;

  rejectDocument() {
    this.rejectVM.id = this.id;
    this.loading = true;
    agent.BusinessDocuments.reject(this.rejectVM).then(() => {
      this.loading = false;
      Notifier.showPositive(`You have succesfully rejected the document`);
    });
    return;
  }
  mounted() {
    if (this.$route.query.id) {
      agent.BusinessDocuments.getRejectionComments(this.id).then((vm) => {
        if (vm.id != NIL_UUID) this.rejectVM = vm;
      });
    }
  }
}
